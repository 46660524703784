.TextBarHead {
  font-family: "Nunito", sans-serif;
  display: flex;
  justify-content: space-between;
  color: white;
  align-items: center;
  padding: 0.8rem 0;
  height: 55px;
  background-color: black;
  img {
    height: 20px;
    width: 30px;
    margin-right: 6px;
  }
  h5 {
    margin: 0;
    text-align: center;
  }
}
.imgLogo {
  cursor: pointer;
  padding-left: 0.8rem;
}
.imgLogo button {
  outline: none;
  background-color: transparent;
  border: none;
}

.ImgLogoCont {
  display: flex;
}
.secondCont {
  display: flex;
  padding-right: 1.7rem;
  p {
    margin: 0;
  }
}
