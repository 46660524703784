.readblogContaienr {
  margin: 2rem auto;
}

.bloghead {
  margin: 3rem auto 2rem auto;
}

.blogbydetails {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.blogbydetails p {
  margin: 0;
  color: #172b4d;
}

.blogbydetails img {
  width: 40px;
}

.blogMAinImg img {
  width: 100%;
  max-height: 500px;
}

.blogList ul {
  list-style: none;
  padding: 0;
}

.blogListItem {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.blogListItem img {
  width: 5rem;
  height: 5rem;
}

.blogListItem p {
  margin: 0;
  color: #172b4d;
}

.blogListItem h6 {
  color: #42526e;
  margin: 0;
}

.blogList ul li {
  border-bottom: 1px solid #172b4d;
  padding: 1rem 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.blogList ul li:hover {
  background-color: #d4d4d4;
}

.gotoquery {
  margin: 1.5rem 0;
}

.gotoqueryContaienr {
  background-color: #fca130;
  padding: 1.5rem;
  text-align: center;
  border-radius: 6px;
}

.gotoqueryContaienr h5,
.gotoqueryContaienr p {
  color: #ffffff;
}

.gotoqueryContaienr h5 {
  margin-bottom: 1.5rem;
}

.gotoqueryContaienr p {
  margin-bottom: 2rem;
}

.avilbtn2 {
  background-color: #deebff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  transition: all 0.2s ease;
  color: #091e42;
}

.avilbtn2:hover {
  background-color: #ffffff;
  box-shadow: 0px 2.461538553237915px 9.230770111083984px 0px #0000001c;
}

.blogTextContainer {
  margin: 2rem auto;
}

.readblogtext p {
  line-height: 1.8rem;
  margin-bottom: 1.5rem;
}

.blogsubhead h4 {
  margin-bottom: 1.5rem;
}

@media only screen and (max-width: 991px) {
  .blogListItem p {
    font-size: 15px;
  }

  .padright {
    padding-right: 0;
  }

  .readblogContaienr {
    margin: 3rem 1rem;
  }
}

@media only screen and (max-width: 576px) {
  .readblogContaienr {
    margin: 2rem 0;
  }
}

/* ------------------------------------------blog view Section-------------------------------------- */

.blog {
  padding: 2rem 10px;
  margin-bottom: 3rem;
}

.bogscard {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px 0px #00000040;
  cursor: pointer;
}

.bogscard img {
  width: 100%;
}

.blogtext {
  padding: 1rem;
}

.blogtext p {
  margin: 0;
  font-size: 13px;
}

.blogsCardsContaier {
  margin-top: 1.5rem;
}

a:hover,
a {
  text-decoration: none;
  color: #000;
}

.readblogContaienr a:hover,
.readblogContaienr a {
  text-decoration: none;
  color: #5837c4 !important;
}
