* {
  margin: 0px;
  padding: 0;
  box-sizing: border-box;
}

.notepad_wrapper {
  /* display: flex;
  flex-direction: column; */
  /* background-color: #baeafc; */
  background: linear-gradient(to bottom, #9374fae5, #a692e6);
  /* width: 100%; */
  overflow: hidden;
  /* height: 100vh !important; */
}

.contaianerNotepad {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin: auto;
}

.notepad {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.txtara {
  flex: 1;
  width: 100%;
  box-sizing: border-box;
}

/* Notepad container */
.notepad {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  font-family: Arial, sans-serif;
  border-radius: 10px;
  margin: 20px;
  height: 100%;
}

/* Toolbar */
.notepad .toolbar {
  display: flex;
  align-content: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}

/* Toolbar buttons */

/* Toolbar buttons hover effect */

/* Word count */
.notepad .toolbar .word-count {
  display: inline-block;
  margin-left: 10px;
}

/* Font size select */

/* Textarea */
.notepad textarea {
  width: 100%;
  height: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 10px;
  transition: box-shadow 0.3s;
}

/* Textarea placeholder */
.notepad textarea::placeholder {
  color: #ccc;
}

/* Textarea focus style */
.notepad textarea:focus {
  outline: none;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

/* Save button */

/* Save button hover effect */

/* Error message */
.notepad .error-msg {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

/* Mobile Responsive Adjustments */

@media (max-width: 650px) {
  .notepad {
    padding: 15px;
    margin: 0 auto;
    width: 100% !important;
    border-radius: 0;
    box-shadow: none;
  }

  .notepad textarea {
    margin: auto;
  }

  .notepad .toolbar button,
  .notepad .toolbar select {
    padding: 8px 12px;
    font-size: 14px;
    margin-right: 3px;
    margin-bottom: 5px;
  }

  .h1 {
    font-size: 1.5rem;
    align-items: center;
  }
}

@media (max-width: 666px) {
  .hideAfter666 {
    display: none !important;
  }

  .toolbar {
    margin-top: 0.3rem;
  }

  .tool {
    flex-direction: column;
    justify-content: space-around;
  }

  .paddingAfter420 {
    padding: 0 !important;
  }

  .fjj2 {
    display: contents !important;
    position: fixed;
    bottom: 0px;
    width: 100%;
    /* Remove the quotes */
    background-color: #f0f0f0;
    /* Use the correct property name */
    padding: 10px;
    text-align: center;
    z-index: 9999;
    /* Adjust the value as needed */
  }

  /* .notepad {
    padding-bottom: 2rem !important;
  } */
}

@media (min-width: 666px) {
  .showAfter666 {
    display: none !important;
  }
}

@media (max-width: 530px) {
  .hideAfter530 {
    display: none !important;
  }

  svg {
    width: 1rem !important;
    height: 1rem !important;
  }

  .fjj2 span {
    font-size: 13px !important;
  }
}

@media (min-width: 530px) {
  .showAfter530 {
    display: none !important;
  }
}

@media (max-width: 510px) {
  .hideAfter510 {
    display: none;
  }

  .logoHead img {
    width: 3rem;
  }

  .casesBtns {
    gap: 0.3rem !important;
    padding: 0 !important;
  }
}

@media (max-width: 415px) {
  .notepad .toolbar button,
  .notepad .toolbar select {
    padding: 0.3rem 0.2rem;
  }

  .gapafter420 {
    gap: 0.3rem !important;
  }

  .notepad .toolbar button,
  .notepad .toolbar select {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 415px) {
  .tool {
    padding: 8px 4px !important;
  }
}

svg {
  fill: rgb(88, 56, 191, 255);
}

h1 {
  margin: 0.8rem 0.5rem;
}

.fjj {
  display: flex;
  align-items: center;
}

.tool {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  cursor: pointer;
  padding: 0.3rem 0.5rem;
  border-radius: 4px;
  transition: background-color 0.2s;
  border: none;
  background-color: transparent;
  margin-bottom: 0.3rem;
}

select {
  font-size: 14px;
  border-radius: 6px;
  border-color: #a692e6;
  border-width: 2px;
  cursor: pointer;
  outline: none;
  padding: 0.4rem 0;
  background-color: transparent;
}

select:hover {
  background-color: #a692e6;
  color: #ffffff;
}

.tool:hover {
  background-color: #a692e6;
  color: #ffffff;
}

.tool:hover svg {
  fill: #ffffff;
}

.icon-label {
  font-size: 14px;
}

.word-count {
  margin-bottom: 7px;
  padding: auto;
}

.txtara {
  width: 100%;
  height: 100%;
}

.fontselector select {
  border-radius: 6px;
  padding: 0.2rem 0.5rem;
  font-size: 15px;
}

.h100 {
  height: 100% !important;
}

.tool_word {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.casesBtns {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0 0.5rem;
}

.casesBtns button {
  padding: 0.3rem 0.5rem;
  background-color: transparent;
  border-radius: 6px;
  border-color: #a692e6;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-size: 14px;
}

.casesBtns button:hover {
  background-color: #a692e6;
  color: #fff;
}

.tool svg {
  width: 18px;
}

.logoHead img {
  width: 2.5rem;
}

.logoHead {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.policyViewBtns {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 0.5rem;
}

.policyViewBtns p {
  margin: 0;
  font-size: 12px;
}

.policyViewBtns button {
  border: none;
  font-weight: 600;
  font-size: 12px;
  background-color: transparent;
}

.fontWeights {
  font-weight: 600 !important;
}

a {
  text-decoration: none;
}

.navbar-collapse {
  flex-grow: 0;
}

.header {
  position: sticky;
  top: 0;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}
