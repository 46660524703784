.email-info-container {
  padding:10px;
}

.body {
  padding-top: 65px;
}

.heading {
  font-size: 2rem;
  color: #333;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
  /* Add fade-in animation */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.legalInfo p {
    font-size: 14px;
}