#about h2 {
  text-align: center;
  padding: 3rem 0;
  font-size: 50px;
  animation: fadeIn 1s ease-in-out;
  background-color: #a692e6;
  color: #ffffff;
}

#about {
  text-align: center;
}

#features {
  margin-top: 3rem;
  text-align: left;
}

#features h3 {
  margin-bottom: 2rem;
}

.whyLi li {
  margin-bottom: 1rem;
}

#features ul li {
  margin-bottom: 1rem;
}

#what-is {
  margin-top: 5rem;
  text-align: left;
}

#what-is h2 {
  margin-top: 4rem;
}

#navbar {
  width: 100%;
  position: sticky;
  top: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

#what-is h3 {
  margin-bottom: 2rem;
}

.headImg {
  width: 100%;
}

.headImg img {
  width: 100%;
  border-radius: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 0 0.5rem;
}

.headText {
  height: 100%;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .colRevrs {
    flex-direction: column-reverse !important;
    gap: 1.5rem !important;
  }
}

.headText p {
  font-weight: 500;
  margin: 0;
}

.imgTextSection {
  margin: 2rem 0;
}

.headImg iframe {
  width: 100%;
  height: 400px;
}
