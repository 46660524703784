.feedback-modal {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.feedback-modal h2 {
  font-size: 18px;
  margin-bottom: 20px;
}
.checkbox-group {
  display: flex;
  justify-content: start;
  gap: 0.5rem;
  margin-bottom: 20px;
}

.circle-checkbox {
  display: flex;
  align-items: center;
}

.circle-checkbox input {
  position: relative;
  margin-right: 8px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid gray;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.circle-checkbox input:checked::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #000;
}

.circle-checkbox label {
  font-weight: bold;
}

.improvement-label {
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  display: block;
  /* margin-bottom: 10px; */
}

.improvement-textarea {
  padding: 0.2rem;
  resize: none;
  border-radius: 3px;
  width: 100%;
  height: 80px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 20px;
  outline: none;
}

.submit-button,
.closeBTn {
  width: 100%;
  border-radius: 1.5rem;
  background: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 12px;
  font-weight: 600;

  cursor: pointer;
}

.submit-button:hover,
.closeBTn:hover {
  background: #0056b3;
}

.FeedBackModal .modal-header {
  border: none !important;
  padding-bottom: 1.5rem;
}
.FeedBackModal .modal-title {
  display: flex;
  width: 100%;
  justify-content: start;
  padding-left: 0 !important;
  border: none !important;
}
.FeedBackModal .modal-content {
  border-radius: 4px;
}
.FeedBackModal .modal-body {
  margin-top: -1.5rem;
}

#feedbackForm {
  padding-left: 4px;
}
.thank-you-message {
  padding-top: 2rem;
}
.thank-you-message h4 {
  font-size: 20px;
  margin: 0;
}
.closeBTn {
  width: 30%;
}

.Feedbackcontainer {
  background-image: url("https://www.toptal.com/designers/subtlepatterns/patterns/concrete-texture.png");
  display: flex;
  flex-wrap: wrap;

  align-items: start;
  justify-content: start;
  /* padding: 0 20px; */
}

.rating {
  padding: 0.5rem 0;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  overflow: hidden;
  flex-direction: row-reverse;
  /* height: 150px; */
  position: relative;
}

.rating > input {
  display: none;
}

.rating > label {
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin-top: auto;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23e3e3e3' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 76%;
  transition: 0.3s;
}

.rating > input:checked ~ label,
.rating > input:checked ~ label ~ label {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23fcd93a' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
}

.rating > input:not(:checked) ~ label:hover,
.rating > input:not(:checked) ~ label:hover ~ label {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23d8b11e' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
}

.feedback {
  max-width: 360px;
  background-color: #fff;
  width: 100%;
  /* padding: 30px; */
  margin-bottom: 1.5rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}
