.topNavBar {
  width: 100%;
  overflow: hidden;
  background-color: #000000;
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  font-weight: 400;
  top: 0;
  position: fixed;
  z-index: 100;
  color: white;
  transition: background-color 0.3s ease;
}

.navbar-dark .navbar-nav .nav-link {
  color: #ffffff !important;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}
.navbar-nav a {
  color: white !important;
  text-decoration: none;
  font-size: 12px;
}

.logoHead {
  padding-left: 1.5rem;
  text-decoration: none;
  display: flex;
}
.navbar-brand {
  display: flex;
}
.navbar-toggler {
  outline: none !important;
  box-shadow: none !important;
  border: none;
}
.logotitle h6 {
  margin: 0;
}
.logotitle a {
  color: white;
  text-decoration: none !important;
}
.nav-item a {
  color: #2c89f3;
}
#navbarNav {
  /* background-color: black; */
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}
.nav-link {
  margin-left: 10px;
}
.navbar-nav {
  padding-right: 1.5rem;
  margin-top: 10px;
}
.headerBttmMargin {
  margin-bottom: 3rem;
}
.headerBttmMargin2 {
  margin-bottom: 4.1rem;
}
.logotitle {
  margin-left: 4px;
  h6 {
    text-decoration: none;
    color: white;
  }
}
