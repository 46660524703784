.notes {
  height: 100%;
  /* overflow-y: hi; */
  overflow-x: hidden;

  width: 100%;
}

.notes * {
  font-family: "Nunito", sans-serif;
}

.notes__sidebar {
  height: 100vh;
  flex: 0 0 30%;
  max-width: 300px;
  margin-left: -300px;
  width: 100%;
  z-index: 60;
  overflow-y: auto;
  border-right: 1px solid #e7eaec;
  background-color: white;
  transition: all 0.2s ease;
}
.EditorContainer {
  flex: 1;
  overflow: hidden !important;
}
.editorBOx {
  transition: margin-left 0.2s ease;
}

.notes__sidebar.sidebar-open {
  margin-left: 0; /* Show sidebar */
}

.TexteditBtns {
  display: flex;
  gap: 10px;
  border-radius: 0;
  position: relative;
  /* width: 100%; */
  button {
    background-color: transparent;
    color: black;
    border: none;
  }
}

.TexteditBtns button::after {
  content: none !important;
}

.TexteditBtns button img {
  width: 0.9rem;
  margin-left: 0.2rem;
  margin-top: -2px;
}

.CreateNOteBTn {
  width: 100%;
  display: flex;
  align-items: center;

  justify-content: center;

  margin-top: 1rem;
  border: transparent;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}
.CreateNOteBTn button {
  background: #5a3ac6;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  font-size: 1.25em;
  font-weight: bold;
  margin-bottom: 1em;
  padding: 0.5rem 0;
  width: 100%;
  font-size: 16px;
}

.btn-group {
  display: flex;
  gap: 10px;

  padding: 0.5rem 0 0.5rem 1rem;
  button {
    font-size: 13px;
  }
}
.editorPlace {
  transition: all 0.3s ease;
  margin-left: 0 !important;
  width: 100%;
}

.editorPlace button {
  border-radius: 0;
  color: black;
  border: none;

  background-color: transparent;
}
.notes__add:hover {
  background: #00af8c;
}
.btn-group .btn {
  border-radius: 10px;
}
.btn-group .btn:hover {
  border-radius: 10px;
  color: black;
  background-color: #a9a9a9c7;
}
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  color: black;
  background-color: #a9a9a9c7;
}
.notes__list-item {
  padding: 0.9rem;
  cursor: pointer;
  font-size: 13px;
}

.notes__list-item--selected {
  background: #eeeeee;

  font-weight: bold;
}

.notes__small-title {
  font-size: 15px;
}

.notes__small-body {
  padding: 0 10px;
}

.notes__small-updated {
  font-size: 12px;
  color: #aaaaaa;
}

.notes__preview {
  border: 1px solid #e7eaec;
  height: calc(100vh - 103px);
}

.notes__title,
.notes__body {
  padding-left: 1rem;
  border: none;
  outline: none;
  width: 100%;
}

.titleText {
  width: 100%;
  display: flex;

  justify-content: space-between;
  border-bottom: 1px solid #e7eaec;
}
.deleteBtn {
  border-left: 1px solid #e7eaec;
  display: flex;
  align-items: center;

  padding-left: 1.5rem;
  padding-right: 1.5rem;
  img {
    height: 20px;
    height: 20px;
  }
  button {
    background-color: transparent;
    border: none;
  }
}
.notes__search input {
  padding: 0.7rem 1.4rem;
  border-radius: 0px;
  box-shadow: none !important;

  border: 0;
  border-top: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
}
.notes__search input::placeholder {
  color: #959595;
}

.notes__title {
  font-size: 1.375rem;
  height: 49px;
  color: #b0b0b0;
}

.notes__body {
  flex-grow: 1;
  font-size: 11pt;
  line-height: 1.5;
  margin-top: 1em;
  resize: none;
}
.editor-info {
  z-index: 88;
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f6f9fa;
  border-bottom: 1px solid #e9ecef;
  border-right: 1px solid #e9ecef;
  label {
    font-size: 13px;
  }
}
.LineCounter label {
  margin-right: 7px;
}
.word-count-info {
  font-size: 14px;
  color: #495057;
}

/* Hide line and column info by default */
.line-column-info {
  display: none;
  font-size: 13px;
}

.editor-focused .line-column-info {
  display: flex;
  gap: 20px;
}

.line-info {
  font-size: 13px;
  padding-right: 5px;
}
.column-info,
.word-count-info {
  font-size: 13px;
}
.pntt,
.FindOption {
  padding-top: 5px;
  border-top: 1.4px solid #e9ecef;
}
.RedoSection {
  border-bottom: 1.4px solid #e9ecef;
}
.SavUSSectio {
  padding-bottom: 5px;
}
.cutSection {
  padding-top: 5px;
}
/* Animation for dropdowns */
.dropdown-menu {
  font-size: 13px;
  transition: opacity 0.3s ease-in-out;
}

/* Hide and show animation for dropdowns */
.dropdown-menu.show {
  border-radius: 0px;
  opacity: 1;
}

.dropdown-menu:not(.show) {
  opacity: 0;
}

/* Button animations */
.btn-group .btn {
  transition: transform 0.2s ease-in-out;
}

.btn-group .btn:hover {
  transform: scale(1.05);
}
.FindReplace .modal-body {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.FindReplace .modal-body input {
  border-radius: 0;
  border: 1px solid #d9d9d9;
  padding: 0.3rem;
}

.FindReplace .modal-content {
  border-radius: 0px;
}
.FindReplace .modal-footer button {
  background-color: #348efb;
  padding: 0.3rem 1.5rem;
}
.CHeckMatchs {
  display: flex;
  flex-direction: column;
  label {
    font-weight: 400;
    margin-left: 10px;

    font-size: 15px;
  }
}
.sybolchooseModal2 .modal-content {
  border-radius: 5px;
}
.sybolchooseModal .modal-body button,
.sybolchooseModal2 .modal-body button {
  background-color: transparent;
  border: none;
}
.emogiCOnt {
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  gap: 5px;
}
.sybolchooseModal2 .modal-header {
  padding: 10px 13px;
}
.InsertEmogyBtn {
  text-align: center;
  padding: 0.4rem 1.7rem;
  background-color: #348efb;
  border-radius: 3px;
  color: white;
}
.ButtnCont {
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
  background-color: #f6f9fa;
}
.fullScrnn {
  padding-right: 1rem;
  img {
    height: 15px;
    width: 15px;
  }
}
.dropdown-item {
  position: relative;
  display: flex;
  align-items: center;
}
.dropdown-item:hover {
  background-color: #a9a9a9c7;
}
.rightMarkImg {
  height: 20px;
  width: 20px;
}
.ql-toolbar.ql-snow {
  border: none;
  box-shadow: none;
  outline: none;
}
.scroll-container {
  height: 108px;

  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  button {
    background-color: transparent;
    border: transparent;
    text-align: start;
  }
  p {
    margin: 0;
  }
}

.scroll-options {
  height: 108px;
  flex-direction: column;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  white-space: nowrap;
  button {
    padding: 0;
    margin: 0;
    border-radius: 0;
    padding-left: 6px;
    padding-bottom: 2px;
    font-size: 14px;
  }
}

.option-btn {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 4px;

  padding: 5px 10px;
  cursor: pointer;
  white-space: nowrap;
  transition: background-color 0.3s ease;
}
.option-btn.active {
  background-color: #cecece;
}
.FontModal .modal-content {
  border-radius: 0;
}
.resetButtn {
  border: none;
  padding: 0.4rem 1rem;
  background-color: #348efb;
}
.resetButtn:hover {
  background-color: #348dfba9;
}
.FontModal {
  animation: none !important;
  transition: none !important;
}

.show-more-btn button {
  border: transparent;
  border-radius: 10px;
  background-color: #348efb;
  font-size: 13px;
}

.displayFlex {
  display: flex;
  width: 100%;
  height: 100%;
}

.text-editor-container {
  overflow: auto;
  height: calc(100vh - 55px);
}

.ql-toolbar.ql-snow {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}
.quill-editor {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow: auto;
}
.showBodyText {
  font-weight: 500;
  font-size: 12px;
}
.text-editor-container {
  height: calc(100vh - 55px);
}

.textAreraSectiom {
  height: calc(94vh - 154px);
}

.quill {
  height: calc(94vh - 196px);
}

.inputHeadingleft {
  display: flex;
  width: 100%;
}
.emojiCOnt {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
}

.emojiCOnt button {
  background-color: white;
  padding: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.1s ease;
}

.emojiCOnt button:hover {
  background: #b5d5ff;
}
.emojiCOnt button.active {
  background: #b5d5ff;
}
.FindReplace input {
  box-shadow: none !important;
  outline: none !important;
}
.FindReplace button {
  border: none;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .notes__sidebar {
    width: 300;
    margin-left: -500px;
    position: fixed;
    top: 10;
    left: 0;
    height: 100vh;
    overflow-y: auto;
    z-index: 100;
    transition: all 0.3s ease;
  }

  .notes__sidebar.sidebar-open {
    margin: 0;
    width: 300px;
  }
  .editorPlace {
    margin-left: 0;
  }
  .textAreraSectiom {
    padding: 0.1rem;
  }
}

/* Styles for each font */
.ql-font-Arial {
  font-family: Arial, sans-serif;
}

.ql-font-Courier-New {
  font-family: "Courier New", monospace;
}

.ql-font-Georgia {
  font-family: Georgia, serif;
}

.ql-font-Times-New-Roman {
  font-family: "Times New Roman", serif;
}

.ql-font-Verdana {
  font-family: Verdana, sans-serif;
}

/* Styles for the font picker */
.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: attr(data-value) !important;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Arial"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Arial"]::before {
  font-family: "Arial", sans-serif;
  content: "Arial" !important;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Courier-New"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Courier-New"]::before {
  font-family: "Courier New", monospace;
  content: "Courier New" !important;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Georgia"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Georgia"]::before {
  font-family: "Georgia", serif;
  content: "Georgia" !important;
}

.ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value="Times-New-Roman"]::before,
.ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="Times-New-Roman"]::before {
  font-family: "Times New Roman", serif;
  content: "Times New Roman" !important;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Verdana"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Verdana"]::before {
  font-family: "Verdana", sans-serif;
  content: "Verdana" !important;
}
.ql-snow .ql-picker.ql-lineHeight .ql-picker-label::before,
.ql-snow .ql-picker.ql-lineHeight .ql-picker-item::before {
  content: attr(data-value) !important;
}

.ql-lineHeight-1 {
  line-height: 1;
}
.ql-lineHeight-1.15 {
  line-height: 1.15;
}
.ql-lineHeight-1.5 {
  line-height: 1.5;
}
.ql-lineHeight-2 {
  line-height: 2;
}
.ql-lineHeight-2\\.5 {
  line-height: 2.5;
}
.ql-lineHeight-3 {
  line-height: 3;
}
.ql-snow .ql-picker{
  width: 58px;
}