/*--------Hero section-----*/
.heroSec {
  padding-top: 130px;
  font-family: "Roboto", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #5a3ac6;
  height: 100%;
  justify-content: center;
  width: 100%;
}
.content {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    margin-left: 0;
  }
  p {
    padding-left: 4px;
  }
}
.startUsingbtn {
  margin-top: 0.5rem;
  background-color: transparent;
  border: 1px solid white;
  padding: 0.7rem 1.5rem;
  color: white;
}
.startUsingbtn:hover {
  background-color: white;

  color: #5a3ac6;
}

.features {
  cursor: normal;
  padding: 70px 0;
  background-color: #eff2f7;
}

.card {
  background-color: transparent;
  border: transparent;

  /* transition: transform 0.3s; */
}

/* .card:hover {
  transform: translateY(-10px);
} */
.card-body {
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: transparent;
  h5 {
    font-size: 15px;
  }
  p {
    letter-spacing: 0px;
    text-align: justify;
    font-size: 14px;
  }
}
.overView {
  padding: 32px 0;
  border-bottom: 1px solid #edeff2;

  p {
    margin: 0;
    padding-top: 0.5rem;
    font-size: 14px;
  }
  h3 {
    padding-bottom: 0.3rem;
    width: fit-content;
    border-bottom: 1px solid #5a3ac6;
    font-size: 22px;
  }
}
.tutorialList {
  padding-top: 3px;
}
.tutorialList a,
.tutorialList2 a {
  color: black;
  text-decoration: none;
}
.tutorialList li {
  height: 100%;
  font-size: 16px;
  padding-bottom: 10px;
  p {
    /* height: 20px; */
    padding: 0;
    margin: 0;

    color: #2c89f3;
  }
}
.tutorialList li :hover {
  width: fit-content;
  border-bottom: 1.3px solid #2c89f3;
}
.textTool {
  display: flex;
}
.ListTxt {
  padding-top: 10px;
}

.textTool span {
  height: 30px;
  cursor: pointer;

  color: #2c89f3;
}
.textTool span:hover {
  border-bottom: 1.3px solid #2c89f3;
}
.textTool {
  cursor: text;
}

.Chatgptimg {
  align-items: center;
  padding-top: 40px;
  flex-direction: column;
  border-radius: 13px 13px 0px 0px;
  overflow: hidden;
  display: flex;
  width: 100%;
  justify-content: center;
  img {
    border-radius: 13px 13px 0px 0px;
    overflow: hidden;
    height: 90%;
    width: 80%;
  }
}

.card-body img {
  height: 40px;
  width: 40px;
}
.card-title {
  margin-top: 5px;
}
.slick-prev:before,
.slick-next:before {
  display: none;
  color: black !important ;
}
.slick-prev {
  left: -11px !important;
}
.slick-next {
  right: -11px !important;
}

/*-----------Floating Button Css-----------*/
.iconflotSubcont {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 0.5rem;
  position: fixed;
  z-index: 10;
  bottom: 40%;
  right: 0;
  animation: floatUpDown 2s ease-in-out infinite;
}
.textNotpadconfloting {
  bottom: 80%;
}
@keyframes floatUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
.icon-container {
  cursor: pointer;
  background-color: #5a3ac6;
  padding: 1rem;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.3s;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.icon-container:hover {
  background-color: #643de1;
  transform: rotate(12deg);
}

.shopping-bag-icon {
  color: white;
  transition: transform 0.3s;
}

/*------------hoverflow------------------------------------*/

.hover-text {
  cursor: pointer;
  background-color: #2d3748;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;

  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  max-width: 200px;

  p {
    cursor: pointer;
    font-size: 12px;
  }
}

.close-button {
  position: absolute;
  top: -8px;
  left: -8px;
}
.close-icon {
  padding: 3px;
  height: 22px;
  width: 22px;
  background-color: white;
  color: black;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: #f87171;
  }
}

@media only screen and (max-width: 600px) {
  .overView p {
    font-size: 13px;
  }
  .heroSec {
    padding-top: 0px;
    height: 90vh;
    img {
      height: 100%;
      width: 95%;
    }
  }
  .Chatgptimg {
    position: absolute;
    bottom: 0px;
  }
}
@media only screen and (max-width: 960px) {
  .textNotpadconfloting {
    bottom: 88%;
  }
}
@media only screen and (max-width: 800px) {
  .textNotpadconfloting {
    bottom: 3%;
  }
}
@media only screen and (min-width: 1400px) {
  .textNotpadconfloting {
    bottom: 87%;
  }
}
