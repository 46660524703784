.Footercontent {
  text-align: -webkit-center;
  padding-top: 30px;
  padding-bottom: 10px;

  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: black;
  h5 {
    padding-bottom: 10px;
    width: fit-content;
    height: 15px;
    text-align: center;
    font-size: 15px;
  }
  h5:hover {
    border-bottom: 1.5px solid white;
  }
  a {
    color: white;
    text-decoration: none;
  }
  a:hover {
    color: white;
  }

  p {
    color: #9f9f9f;
    font-size: 15px;
  }
}
